<template>
    <section class="section__container container container_active separator-when-scrolling plr__12 pb__12">
        <div class="card ptb__4">
            <table class="table">
                <tr>
                    <th>1</th>
                    <th>Panic Button:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>2</th>
                    <th>Aftertreatment 1 Diesel Exhaust Fluid Tank 1 Level (%):</th>
                    <td>97</td>
                </tr>
                <tr>
                    <th>3</th>
                    <th>Amber warning lamp (DTC):</th>
                    <td>1</td>
                </tr>
                <tr>
                    <th>4</th>
                    <th>Cruise Control Active:</th>
                    <td>On</td>
                </tr>
                <tr>
                    <th>5</th>
                    <th>Diesel Particulate Filter Active Regeneration Status:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>6</th>
                    <th>Diesel Particulate Filter Lamp Command:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>7</th>
                    <th>Diesel Particulate Filter Status:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>8</th>
                    <th>DPF Thermal Management Active:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>9</th>
                    <th>Engine Coolant Level (%):</th>
                    <td>100</td>
                </tr>
                <tr>
                    <th>10</th>
                    <th>Engine Coolant Temperature C:</th>
                    <td>190.4 °F</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>11</th>
                    <th>Engine Demand Torque (%):</th>
                    <td>59</td>
                </tr>
                <tr>
                    <th>12</th>
                    <th>Engine Fuel Rate (L/h):</th>
                    <td>55</td>
                </tr>
                <tr>
                    <th>13</th>
                    <th>Engine Oil Pressure (kPa):</th>
                    <td>300</td>
                </tr>
                <tr>
                    <th>14</th>
                    <th>SD1 Engine Oil temperature:</th>
                    <td>116.6 °F</td>
                </tr>
                <tr>
                    <th>15</th>
                    <th>Engine Percent Load At Current Speed:</th>
                    <td>73</td>
                </tr>
                <tr>
                    <th>16</th>
                    <th>Engine Red Stop Lamp:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>17</th>
                    <th>Engine Retarder Selection:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>18</th>
                    <th>Failure mode identifier (DTC):</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>19</th>
                    <th>Fuel Level (%):</th>
                    <td>95</td>
                </tr>
                <tr>
                    <th>20</th>
                    <th>High Resolution Total Vehicle Distance (meters):</th>
                    <td>898265355</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>21</th>
                    <th>Parking Brake Switch:</th>
                    <td>1</td>
                </tr>
                <tr>
                    <th>22</th>
                    <th>Red stop lamp (DTC):</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>23</th>
                    <th>Requested Percent Fan Speed:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>24</th>
                    <th>Actual Engine - Percent Torque:</th>
                    <td>8</td>
                </tr>
                <tr>
                    <th>25</th>
                    <th>Actual Engine - Percent Torque High Resolution:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>26</th>
                    <th>Aftertreatment 1 Diesel Exhaust Fluid Concentration:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>27</th>
                    <th>Aftertreatment 1 Diesel Exhaust Fluid Property:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>28</th>
                    <th>Aftertreatment 1 Diesel Exhaust Fluid Tank 1 Low Level Indicator:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>29</th>
                    <th>Cruise Set Speed:</th>
                    <td>116</td>
                </tr>
                <tr>
                    <th>30</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Accelerator Pedal Off Idle:</th>
                    <td>0</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>31</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Clutch Disengaged:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>32</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Engine Not Warmed Up:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>33</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Inhibit Switch:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>34</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Low Exhaust Gas Temperature:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>35</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Out of Neutral:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>36</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Service Brake Active:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>37</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to System Fault Active:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>38</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Vehicle Speed Above Allowed
                        Speed:
                    </th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>39</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Due to Vehicle Speed Below Allowed
                        Speed:
                    </th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>40</th>
                    <th>Diesel Particulate Filter Active Regeneration Inhibited Status:</th>
                    <td>0</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>41</th>
                    <th>Differential Lock State - Central Rear:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>42</th>
                    <th>Engine Speed (RPM):</th>
                    <td>1547</td>
                </tr>
                <tr>
                    <th>43</th>
                    <th>Engine Total Fuel Used - J1939 (L):</th>
                    <td>354846</td>
                </tr>
                <tr>
                    <th>44</th>
                    <th>Engine Total Hours Of Operation:</th>
                    <td>20910</td>
                </tr>
                <tr>
                    <th>45</th>
                    <th>Engine Total Idle Hours:</th>
                    <td>11727</td>
                </tr>
                <tr>
                    <th>46</th>
                    <th>Tire Condition Location:</th>
                    <td>33</td>
                </tr>
                <tr>
                    <th>47</th>
                    <th>Tire Condition Pressure (kPa):</th>
                    <td>1016</td>
                </tr>
                <tr>
                    <th>48</th>
                    <th>Tire Condition Temperature (c):</th>
                    <td>1766</td>
                </tr>
                <tr>
                    <th>49</th>
                    <th>Tire Information Location:</th>
                    <td>35</td>
                </tr>
                <tr>
                    <th>50</th>
                    <th>Tire Information Pressure (kPa):</th>
                    <td>752</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>51</th>
                    <th>Tank 1:</th>
                    <td>93.55 %</td>
                </tr>
                <tr>
                    <th>52</th>
                    <th>Tank 2:</th>
                    <td>92.18 %</td>
                </tr>
                <tr>
                    <th>53</th>
                    <th>Aftertreatment 1 Diesel Exhaust Fluid Tank 1 Temperatue:</th>
                    <td>25</td>
                </tr>
                <tr>
                    <th>54</th>
                    <th>Aftertreatment 1 Diesel Particulate Filter Intake Gas Temperature:</th>
                    <td>305</td>
                </tr>
                <tr>
                    <th>55</th>
                    <th>Aftertreatment 1 Diesel Particulate Filter Outlet Gas Temperature:</th>
                    <td>279</td>
                </tr>
                <tr>
                    <th>56</th>
                    <th>Aftertreatment 1 Exhaust Dew Point:</th>
                    <td>1</td>
                </tr>
                <tr>
                    <th>57</th>
                    <th>Aftertreatment 1 Gas Oxidation Catalyst Intake Gas Temperature:</th>
                    <td>320</td>
                </tr>
                <tr>
                    <th>58</th>
                    <th>Aftertreatment 1 Intake Dew Point:</th>
                    <td>1</td>
                </tr>
                <tr>
                    <th>59</th>
                    <th>Aftertreatment 1 SCR Catalyst Intake Gas Temperature:</th>
                    <td>95</td>
                </tr>
                <tr>
                    <th>60</th>
                    <th>Aftertreatment 1 SCR Catalyst Outlet Gas Temperature:</th>
                    <td>87</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>61</th>
                    <th>Brake Primary Pressure (kPa):</th>
                    <td>1105</td>
                </tr>
                <tr>
                    <th>62</th>
                    <th>Brake Secondary Pressure (kPa):</th>
                    <td>1110</td>
                </tr>
                <tr>
                    <th>63</th>
                    <th>Engine:</th>
                    <td>On</td>
                </tr>
                <tr>
                    <th>64</th>
                    <th>Battery Level:</th>
                    <td>14.14</td>
                </tr>
                <tr>
                    <th>65</th>
                    <th>Front Axle(#999999) Pressure:</th>
                    <td>0</td>
                </tr>
                <tr>
                    <th>66</th>
                    <th>Front Axle(#999999) Temperature:</th>
                    <td>32 °F</td>
                </tr>
                <tr>
                    <th>67</th>
                    <th>Front Axle(#999999) Weight:</th>
                    <td>10960</td>
                </tr>
                <tr>
                    <th>68</th>
                    <th>Drive Axle(#2999381612) Pressure:</th>
                    <td>59.39</td>
                </tr>
                <tr>
                    <th>69</th>
                    <th>Drive Axle(#2999381612) Temperature:</th>
                    <td>99.5 °F</td>
                </tr>
                <tr>
                    <th>70</th>
                    <th>Drive Axle(#2999381612) Weight:</th>
                    <td>30871</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>71</th>
                    <th>Trailer(#2999393292) Pressure:</th>
                    <td>0.0</td>
                </tr>
                <tr>
                    <th>72</th>
                    <th>Trailer(#2999393292) Temperature:</th>
                    <td>95.9 °F</td>
                </tr>
                <tr>
                    <th>73</th>
                    <th>Trailer(#2999393292) Weight:</th>
                    <td>-455237</td>
                </tr>
                <tr>
                    <th>74</th>
                    <th>Trailer(#299939392) Pressure:</th>
                    <td>100.6</td>
                </tr>
                <tr>
                    <th>75</th>
                    <th>Trailer(#299939392) Temperature:</th>
                    <td>53.6 °F</td>
                </tr>
                <tr>
                    <th>76</th>
                    <th>Trailer(#299939392) Weight:</th>
                    <td>-87158</td>
                </tr>
                <tr>
                    <th>77</th>
                    <th>Drive Axle(#9999381612) Pressure:</th>
                    <td>62.82</td>
                </tr>
                <tr>
                    <th>78</th>
                    <th>Drive Axle(#9999381612) Temperature:</th>
                    <td>52.7 °F</td>
                </tr>
                <tr>
                    <th>79</th>
                    <th>Drive Axle(#9999381612) Weight:</th>
                    <td>32418</td>
                </tr>
                <tr>
                    <th>80</th>
                    <th>Drive Axle(#999381612) Pressure:</th>
                    <td>62.82</td>
                </tr>

                <tr class="tr_type_separator">
                    <th></th>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>81</th>
                    <th>Drive Axle(#999991612) Temperature:</th>
                    <td>52.7 °F</td>
                </tr>
                <tr>
                    <th>82</th>
                    <th>Drive Axle(#999991612) Weight:</th>
                    <td>32418</td>
                </tr>
                <tr>
                    <th>83</th>
                    <th>Drive Axle(#299938112) Pressure:</th>
                    <td>59.39</td>
                </tr>
                <tr>
                    <th>84</th>
                    <th>Drive Axle(#299938112) Temperature:</th>
                    <td>99.5 °F</td>
                </tr>
                <tr>
                    <th>85</th>
                    <th>Drive Axle(#299938112) Weight:</th>
                    <td>30871</td>
                </tr>
            </table>
        </div>
    </section>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'UnitInfo_Sensors',
        props: [
            'unitId',
        ],
        components: {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
                "unitsByIds",
            ]),
        },
        methods: {
            ...mapActions([
                "setSectionInfo",
            ]),
        },
        mounted() {
        }
    }
</script>